<template> 
    <div class="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
        <div class="font-bold mr-8">SURVEY APP</div>
    </div>
    <Error :errors="errors"/>
    <div class="grid">
        <div class="field col-12 lg:col-6 lg:col-offset-3">
            <Reward :survey="3" ref="survey"/>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import Reward from '../../components/reward/Index.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Error': Error,
		'Reward': Reward,
    },
    data() {
        return {
        }
    },
    created() {
    },
	mounted() {
        this.$store.commit('setErrors', {});
	},
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
    },
}
</script>